import { MouseEvent } from 'react';

/** returns the value given from the mouse */
export function getMouseIncrease(e: MouseEvent) {
  e.preventDefault();
  const mouse_clicks = [
    'LEFTCLICK',
    'MIDDLECLICK',
    'RIGHTCLICK',
    'ERROR',
    'ERROR',
  ];
  if (mouse_clicks[e.button] === 'LEFTCLICK') return 1;
  if (mouse_clicks[e.button] === 'RIGHTCLICK') return -1;
  return 0;
}
