import React from 'react';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa';

export default function CategoryRow(props: {
  openChevron: () => void;
  opened: boolean;
  heavyweights_name: string;
}) {
  const { openChevron, opened, heavyweights_name } = props;

  function getWeightName() {
    return `${heavyweights_name}`;
  }

  return (
    <tr className='category-row centered-text'>
      <td colSpan={5}>{getWeightName()}</td>
      <td className='table-column-10 centered-text'>
        <button className='icon-button orange' onClick={openChevron}>
          {(opened && <FaChevronDown />) || <FaChevronRight />}
        </button>
      </td>
    </tr>
  );
}
