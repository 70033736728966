import React from 'react';

export interface SecondWindowContextI {
  second_window: Window | null;
  setSecondWindow: (second_window: Window) => void;
}

export const SecondWindowContext = React.createContext<SecondWindowContextI>({
  second_window: null,
  setSecondWindow: () => {},
});
