import React from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { deleteToken, getToken } from '../Auth/token-service';
/* import dotenv from 'dotenv';
dotenv.config(); */
const backend_url = process.env.REACT_APP_BACKEND_URL;

interface ApiResult {
  status: 'success';
  data: any;
}

interface ApiError {
  status: 'fail' | 'error';
  message: string;
}

type ApiResponse = ApiResult | ApiError;

export class ApiException extends Error {
  constructor(response: ApiError) {
    super(response.message);
  }
}

/**
 * It takes a url, a method and some data, and returns the data from the response
 * @param {string} url - the url of the api call, if it doesn't start with http:// or https:// it will
 * be prefixed with the base api url
 * @param [method=get] - the HTTP method to use, defaults to 'get'
 * @param {any} [data] - the data to send to the server, if any
 * @returns the data property of the res object.
 */
async function apiCall(url: string, method = 'get', data?: any) {
  /* if (!url.startsWith('http://') && !url.startsWith('https://')) {
    // prefix the url with the base api url
    switch (process.env.NODE_ENV) {
      case 'production':
        url = `https://judoincloud-backend.herokuapp.com/api/${url}`;
        break;
      case 'development':
      default:
        url = `http://localhost:2500/api/${url}`;
    }
  } */

  url = `${backend_url}${url}`;
  let res: ApiResponse;
  try {
    const token = getToken();
    const headers: Record<string, string> = {
      'Content-Type': 'application/json',
    };
    if (token !== null) {
      headers.Authorization = `Bearer ${token}`;
    }
    const body = data ? JSON.stringify(data) : undefined;
    const response = await fetch(url, {
      method,
      headers,
      body,
    });
    if (response.status === 401 && token !== null) {
      // unauthorized, if the token exists it's invalid
      deleteToken();
    }
    res = await response.json();
  } catch (error) {
    // generic connection error
    const errSwal = withReactContent(Swal);

    errSwal.fire({
      title: <p>Errore di connessione</p>,
      icon: 'error',
    });
    console.error('[API SERVICE] error', { error });
    throw new ApiException({
      status: 'error',
      message: 'Errore di connessione',
    });
  }

  if (res.status !== 'success') {
    // internal server error or call error
    const errSwal = withReactContent(Swal);

    errSwal.fire({
      title: <p>{res.message}</p>,
      icon: 'error',
    });
    throw res;
  }

  return res.data;
}

/**
 * It makes a POST request to the given URL with the given data, and returns a promise that resolves to
 * the response data
 * @param {string} url - The URL to call.
 * @param {object} data - The data to send to the server.
 * @returns A promise that resolves to a generic type T.
 */
export function apiPost<T = any>(url: string, data: object): Promise<T> {
  return apiCall(url, 'post', data);
}

/**
 * "This function returns a promise that resolves to the response body of a GET request to the given
 * URL."
 *
 * The function is generic, which means that it can be used to return any type of data. The default
 * type is any, which means that the function will return a JavaScript object by default
 * @param {string} url - The URL to call.
 * @returns A promise that resolves to a generic type T.
 */
export function apiGet<T = any>(url: string): Promise<T> {
  return apiCall(url, 'get');
}

/**
 * "This function makes a DELETE request to the given URL and returns the response as a promise."
 *
 * The function is generic, which means that it can return any type of data. The default type is any,
 * which means that the function will return any type of data by default
 * @param {string} url - The URL to make the request to.
 * @returns A function that takes a url and returns a promise that resolves to the response of the api
 * call.
 */
export function apiDelete<T = any>(url: string): Promise<T> {
  return apiCall(url, 'delete');
}

/**
 * "Make a PUT request to the given URL with the given data and return the response as a promise."
 *
 * The function is generic, which means that it can return any type of data. The default type is any,
 * which means that the function will return any type of data by default
 * @param {string} url - The URL to make the request to.
 * @param {object} data - The data to send to the server.
 * @returns A promise that resolves to a generic type T.
 */
export function apiPut<T = any>(url: string, data: object): Promise<T> {
  return apiCall(url, 'put', data);
}
