import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { UserInterface } from './schemas';
import { apiGet } from './Services/Api/api';
import { AuthContext, AuthContextI } from './Services/Auth/AuthContext';
import { getToken } from './Services/Auth/token-service';
import { ThemeContext, ThemeContextI } from './Services/Theme/ThemeContext';
import { getTheme, ThemeT } from './Services/Theme/theme-service';
import {
  SecondWindowContext,
  SecondWindowContextI,
} from './Services/SecondWindow/SecondWindowContext';

function App() {
  const [user, setUser] = useState<UserInterface | null>(null);
  const auth_context: AuthContextI = {
    user,
    setUser,
    unsetUser: () => setUser(null),
  };

  const [theme, setTheme] = useState<ThemeT>(() => getTheme());
  const theme_context: ThemeContextI = { theme, setTheme };

  const [second_window, setSecondWindow] = useState<Window | null>(null);
  const second_window_context: SecondWindowContextI = {
    second_window,
    setSecondWindow,
  };

  useEffect(() => {
    // try loading the user
    const token = getToken();
    if (token !== null) {
      apiGet('auth')
        .then((res: UserInterface) => {
          setUser(res);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, []);

  return (
    <ThemeContext.Provider value={theme_context}>
      <AuthContext.Provider value={auth_context}>
        <SecondWindowContext.Provider value={second_window_context}>
          <div className={theme === 'dark' ? 'dark' : ''}>
            <div className='w-full min-h-screen dark:bg-neutral-800 dark:text-neutral-200 bg-[#6495ed]'>
              <Outlet></Outlet>
            </div>
          </div>
        </SecondWindowContext.Provider>
      </AuthContext.Provider>
    </ThemeContext.Provider>
  );
}

export default App;
