import React from 'react';
import { Outlet } from 'react-router-dom';
import MainNav from './MainNav';

/**
 * It returns a MainNav component, an Outlet component, and a div with the className of h-full
 * @returns The MainLayout component is being returned.
 */
export default function MainLayout() {
  return (
    <>
      <MainNav />
      <div className='h-full'>
        <Outlet />
      </div>
    </>
  );
}
