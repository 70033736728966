import React from 'react';
import { BounceLoader } from 'react-spinners';

/** shows a loading circle */
export default function LoadingPage() {
  return (
    <div className='loading-page'>
      <BounceLoader size={100} />
      <div className="score-text">Sto Caricando...</div>
    </div>
  );
}
