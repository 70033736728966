import React from 'react';

/** big, orange button */
export default function OrangeButton(props: {
  /** what to show inside the button */
  children: React.ReactNode;
  /** function called when the button is clicked */
  onClickFunction?: () => void;
}) {
  const { children, onClickFunction } = props;
  return (
    <button className='tournament-button orange' onClick={onClickFunction}>
      {children}
    </button>
  );
}
