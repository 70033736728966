import React from 'react';
import OrangeButton from '../../../Components/Buttons/OrangeButton';
import { AthleteInterface } from '../../../schemas';

/** shows the winner and the button to end the match */
export default function LastRowWinner(props: {
  winner_athlete: AthleteInterface;
  finishMatch?: () => void;
}) {
  const { winner_athlete, finishMatch } = props;

  return (
    <div className='name-row-flexbox'>
      <div id='winner-sign'>Vince</div>
      <div id='winner-name'>
        {`${winner_athlete.surname} ${winner_athlete.name}`}
      </div>
      <div id='send-match-data-container'>
        {finishMatch && (
          <OrangeButton onClickFunction={finishMatch}>Concludi</OrangeButton>
        )}
      </div>
    </div>
  );
}
