import React from 'react';
import { MatchInterface, TournamentEnhancedInterface } from '../../../schemas';
import Bracket from '../components/Bracket';
import { default_scores } from '../../MatchTimer/common/default_match_states';

export default function SimoneNewBrackets(props: {
  tournament: TournamentEnhancedInterface;
}) {
  const { tournament } = props;

  // TODO this should be modified by the user
  // const compact = tournament.winners_bracket.length > 3;
  const compact = false;

  const main_matches = tournament.matches.filter((m) =>
    m.match_type.includes('winner_bracket')
  );

  const first_recovery_matches = tournament.matches.filter(
    (m) =>
      m.match_type.includes('recovery_bracket_A') ||
      m.match_type.includes('recovery_bracket_B')
  );

  const second_recovery_matches = tournament.matches.filter(
    (m) =>
      m.match_type.includes('recovery_bracket_C') ||
      m.match_type.includes('recovery_bracket_D')
  );

  const created_at = new Date();
  const empty_match: MatchInterface = {
    white_athlete: '',
    red_athlete: '',
    winner_athlete: '',
    tournament: '',
    is_started: false,
    is_over: false,
    scores: default_scores,
    match_type: 'winner_bracket_999_999',
    match_index: 0,
    is_last_match: false,
    created_at,
    last_updated: created_at,
  };

  // * convert the new format of TournamentInterface to the old one, since Bracket.tsx is not updated. The old format used a matrix to differentiate between rounds, instead of using the name in match.match_type
  function convertMainMatchesToBracket(
    matches: MatchInterface[]
  ): MatchInterface[][] {
    const bracket: MatchInterface[][] = [];

    let current_match_round = -1;
    let current_bracket_round = -1;

    for (const m of matches) {
      const match_type_splitted = m.match_type.split('_');
      // m.match_type = `winner_bracket_{match_round}_{number_in_round}`
      const new_match_round = Number(match_type_splitted[2]);

      if (new_match_round !== current_match_round) {
        current_match_round = new_match_round;
        current_bracket_round++;
        bracket.push([]);
      }

      bracket[current_bracket_round].push(m);
    }

    // add empty slots for future matches
    while (current_match_round >= 2) {
      current_match_round /= 2;
      bracket.push([]);
      current_bracket_round++;
      for (let i = 0; i < current_match_round; i++) {
        bracket[current_bracket_round].push(empty_match);
      }
    }

    return bracket;
  }

  function convertRecoveryMatchesToBracket(
    matches: MatchInterface[]
  ): MatchInterface[][] {
    const bracket: MatchInterface[][] = [];

    let current_match_round = -1;
    let current_bracket_round = -1;

    for (const m of matches) {
      const match_type_splitted = m.match_type.split('_');
      // m.match_type = `recovery_bracket_{recovery_bracket_letter}_{match_round}`
      const new_match_round = Number(match_type_splitted[3]);

      if (new_match_round !== current_match_round) {
        current_match_round = new_match_round;
        current_bracket_round++;
        bracket.push([]);
      }

      bracket[current_bracket_round].push(m);
    }

    // add empty slots for future matches
    while (current_match_round >= 1) {
      current_match_round--;
      bracket.push([]);
      current_bracket_round++;
      for (let i = 0; i < current_match_round; i++) {
        bracket[current_bracket_round].push(empty_match);
      }
    }

    return bracket;
  }

  return (
    <div>
      <h2 className='text-xl font-bold pb-1'>Tabellone vincitori</h2>
      <Bracket
        compact={compact}
        bracket={convertMainMatchesToBracket(main_matches)}
      />

      {first_recovery_matches.length > 0 && (
        <>
          <h2 className='text-xl font-bold pb-1 mt-4'>
            Primo tabellone recuperati
          </h2>
          <Bracket
            compact={compact}
            bracket={convertRecoveryMatchesToBracket(first_recovery_matches)}
          />
        </>
      )}

      {second_recovery_matches.length > 0 && (
        <>
          <h2 className='text-xl font-bold pb-1 mt-4'>
            Secondo tabellone recuperati
          </h2>
          <Bracket
            compact={compact}
            bracket={convertRecoveryMatchesToBracket(second_recovery_matches)}
          />
        </>
      )}
    </div>
  );
}
