import React from 'react';
import { AthleteInterface, MatchInterface } from '../../../schemas';

function BracketLines(props: { match: MatchInterface }) {
  const { match } = props;

  const players = [
    match.white_athlete as AthleteInterface,
    match.red_athlete as AthleteInterface,
  ];
  const winner_id = match.winner_athlete ?? null;
  const disputed = winner_id !== null;
  // TODO find a way to communicate here that the looser is recovered or not
  const loser_recovered = false; // match.loser_recovered;

  const forward_lines_elem = players.map((player, player_index) => {
    const player_id = player?._id ?? null;
    const won = disputed && player_id === winner_id;
    const lost = disputed && player_id !== winner_id;
    return (
      <div
        key={player_index}
        className={`h-1/4 border-r-2 bracket-line
        ${won ? 'won' : ''}
        ${lost ? 'lost' : ''}
        ${loser_recovered ? 'recovered' : ''}
        ${player_index === 0 ? 'border-t-2' : 'border-b-2'}`}
      ></div>
    );
  });

  return (
    <div className='flex'>
      {/* 2 forward lines from players */}
      <div>
        <div className='h-1/4'></div>
        {forward_lines_elem}
      </div>
      {/* connecting line */}
      <div>
        <div
          className={`h-1/2 border-b-2 bracket-line
          ${disputed ? 'won' : ''}`}
        ></div>
      </div>
    </div>
  );
}

export default BracketLines;
