import React, { useEffect, useState } from 'react';
import { apiGet, apiPost } from '../../Services/Api/api';
import {
  AthleteInterface,
  NewAthleteInterface,
  AgeClassInterface,
  AgeClassEnhancedInterface,
} from '../../schemas';
import AthleteFormModal from './components/AthleteFormModal';
import AthleteTable from './components/AthleteTable';

export type AthletesPerCategory = {
  [category_id: string]: AthleteInterface[];
};

export default function AthletesPage() {
  const [age_classes, setAgeClasses] = useState<AgeClassEnhancedInterface[]>(
    []
  );
  const [athletes, setAthletes] = useState<AthletesPerCategory>({});
  const [is_new_athlete_open, setIsNewAthleteOpen] = useState(false);

  /** get the age classes */
  useEffect(() => {
    apiGet('age_classes').then((ageClassData: AgeClassEnhancedInterface[]) => {
      setAgeClasses(ageClassData);
      apiGet('athletes').then((athleteData: AthleteInterface[]) => {
        const my_athletes: AthletesPerCategory = {};
        for (const age_class of ageClassData) {
          if (!age_class.categories) continue;
          for (const cat of age_class.categories) {
            const category_id = cat._id;
            if (!category_id) continue;
            my_athletes[category_id] = athleteData.filter(
              (ath) => ath.category === category_id
            );
          }
        }
        setAthletes(my_athletes);
      });
    });
  }, []);

  function updateAthleteFromTable(new_athletes: AthleteInterface[]) {
    if (!new_athletes) return;

    for (const new_athlete of new_athletes) {
      if (!new_athlete) return;
      setAthletes((prevAth) => {
        const category_id: string = new_athlete.category as string;
        const new_category = {
          [category_id]: prevAth[category_id],
        };
        const athlete_to_update = new_category[category_id].findIndex(
          (ath) => ath._id === new_athlete._id
        );
        if (athlete_to_update < 0) new_category[category_id].push(new_athlete);
        else new_category[category_id][athlete_to_update] = new_athlete;
        return { ...prevAth, ...new_category };
      });
    }
  }

  function deleteAthleteFromTable(athleteToDelete: AthleteInterface) {
    if (!athleteToDelete) return;
    setAthletes((prevAth) => {
      const category_id = athleteToDelete.category as string;
      const new_category = {
        [category_id]: prevAth[category_id],
      };
      new_category[category_id] = new_category[category_id].filter(
        (ath) => ath._id !== athleteToDelete._id
      );
      return { ...prevAth, ...new_category };
    });
  }

  function updateAgeClassFromTable(newAgeClass: AgeClassInterface) {
    if (!newAgeClass) return;
    setAgeClasses((prevAgeClasses) => {
      const new_age_classes: AgeClassInterface[] = [];
      for (const age_class of prevAgeClasses) {
        if (age_class._id === newAgeClass._id) {
          new_age_classes.push(newAgeClass);
        } else new_age_classes.push(age_class);
      }
      return new_age_classes;
    });
  }

  return (
    <div className='tournament-container'>
      <div className='search-athlete-container'></div>
      <AthleteTable
        age_classes={age_classes}
        athletes={athletes}
        openNewAthlete={() => setIsNewAthleteOpen(true)}
        updateAthleteFromTable={updateAthleteFromTable}
        deleteAthleteFromTable={deleteAthleteFromTable}
        updateAgeClassFromTable={updateAgeClassFromTable}
      />
      {is_new_athlete_open && (
        <AthleteFormModal
          handleClose={() => setIsNewAthleteOpen(false)}
          updateAthleteFromTable={updateAthleteFromTable}
          apiSend={(params: NewAthleteInterface) =>
            apiPost('athletes', [params])
          }
        >
          Aggiungi Atleta
        </AthleteFormModal>
      )}
    </div>
  );
}
