import React from 'react';
import OrangeButton from '../../../Components/Buttons/OrangeButton';
import { AthleteColor } from '../common/AthleteColor';

/** When clicked, calls startOsk with the given osk owner */
export default function OskStartButton(props: {
  osk_owner: AthleteColor;
  startOsk: (osk_owner: AthleteColor) => void;
}) {
  const { osk_owner, startOsk } = props;

  return (
    <div className='osk-buttons'>
      <OrangeButton onClickFunction={() => startOsk(osk_owner)}>
        Inizia Osk
      </OrangeButton>
    </div>
  );
}
