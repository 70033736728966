import React from 'react';
import { AthleteInterface, MatchInterface } from '../../../schemas';
import BracketLines from './BracketLines';
import Player from './Player';

export default function Match(props: { match: MatchInterface }) {
  const { match } = props;

  const players = [
    match.white_athlete as AthleteInterface,
    match.red_athlete as AthleteInterface,
  ];
  const winner_id = match.winner_athlete ?? null;
  const disputed = winner_id != null;

  const players_elem = players.map((player, players_idx) => {
    const players_id = player?._id ?? null;
    let state: 'won' | 'lost' | 'tbd' = 'tbd';
    if (disputed) {
      state = winner_id === players_id ? 'won' : 'lost';
    }
    return (
      <div className='h-1/2 flex items-center' key={players_idx}>
        <Player player={player} state={state}></Player>
      </div>
    );
  });

  return (
    <div className='flex flex-grow'>
      <div>{players_elem}</div>
      <BracketLines match={match}></BracketLines>
    </div>
  );
}
