import React from 'react';
import { UserInterface } from '../../schemas';

export interface AuthContextI {
  user: UserInterface | null;
  setUser: (user: UserInterface) => void;
  unsetUser: () => void;
}

export const AuthContext = React.createContext<AuthContextI>({
  user: null,
  setUser: () => {},
  unsetUser: () => {}
});
