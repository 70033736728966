import React from 'react';
import { Link } from 'react-router-dom';
import OrangeButton from '../../Components/Buttons/OrangeButton';
import CenteredBox from '../../Components/Layout/CenteredBox';

export default function CompetitionManagementPage() {
  return (
    <CenteredBox>
      <h2 className='text-2xl bold pb-4'>
        Vuoi gestire un torneo o pesare gli atleti?
      </h2>

      <div className='flex flex-wrap max-w-lg'>
        <div className='w-full md:w-1/2 p-3'>
          <Link to='/tournament'>
            <OrangeButton>
              Gestione torneo
            </OrangeButton>
          </Link>
        </div>

        <div className='w-full md:w-1/2 p-3'>
          <Link to='/athletes'>
            <OrangeButton>
              Sezione pesi
            </OrangeButton>
          </Link>
        </div>
      </div>
    </CenteredBox>
  );
}
