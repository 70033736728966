import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { AthleteInterface, TournamentEnhancedInterface } from '../../schemas';
import { apiGet } from '../../Services/Api/api';
import SimoneNewBrackets from './new_components/SimoneNewBrackets';
import SimoneNewRanking from './new_components/SimoneNewRanking';
import SimoneNewMatchTable from './new_components/SimoneNewMatchTable';
import { FaArrowLeft } from 'react-icons/fa';
import { AuthContext } from '../../Services/Auth/AuthContext';

export default function SimoneNewBracketPage() {
  const [tournament, setTournament] = useState<TournamentEnhancedInterface>();
  const current_url = useLocation().pathname;
  const { user } = useContext(AuthContext);

  useEffect(() => {
    apiGet(`${current_url}`).then((t: TournamentEnhancedInterface) => {
      setTournament(t);
      console.log(current_url);
    });
  }, []);

  function getRankingComponent() {
    if (!tournament?.ranking?.length || tournament.ranking.length < 2) {
      return <></>;
    }

    return (
      <SimoneNewRanking
        ranking={tournament.ranking}
        athletes={tournament.athletes as AthleteInterface[]}
      />
    );
  }

  function getBracketComponent() {
    if (!tournament?.athletes?.length || tournament.athletes.length < 2) {
      return <></>;
    }

    /* if (tournament.athletes.length < 5) return <SimoneNewMatchTable tournament={tournament} />;
    return <SimoneNewBrackets tournament={tournament} />; */

    return (
      <>
        {tournament.athletes.length > 5 && (
          <SimoneNewBrackets tournament={tournament} />
        )}
        <SimoneNewMatchTable tournament={tournament} />
      </>
    );
  }

  function getLinkGoBack() {
    if (user) {
      const tournament_id = current_url.split('/')[2];
      return `/tournament?from_tournament=${tournament_id}`;
    } else {
      const slug = current_url.split('/')[1];
      return `/${slug}`;
    }
  }

  return (
    <div style={{ backgroundColor: 'white', height: '92vh' }}>
      <span
        className='flex items-center'
        style={{ fontSize: '2rem', padding: '1rem' }}
      >
        <NavLink to={getLinkGoBack()}>
          <FaArrowLeft className='mr-2' />
        </NavLink>
        Torna indietro
      </span>

      {getRankingComponent()}
      {getBracketComponent()}
    </div>
  );
}
