import React from 'react';
import OrangeButton from '../../../Components/Buttons/OrangeButton';

export default function InfoButton() {
  const onClickFunction = () => {
    console.log('ciao');
  };

  return <OrangeButton onClickFunction={onClickFunction}>Info</OrangeButton>;
}
