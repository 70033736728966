import React from 'react';

/**
 * It takes a children and renders it inside a box
 */
export default function CenteredBox(props: {
  /** what to show inside the box */ children: React.ReactNode;
}) {
  const { children } = props;

  return (
    <div className='w-full min-h-screen flex items-center justify-center'>
      <div className='border dark:border-neutral-400 p-10 rounded-2xl dark:bg-neutral-900 bg-neutral-100'>
        {children}
      </div>
    </div>
  );
}
