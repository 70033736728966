import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { apiGet } from '../../Services/Api/api';
import {
  AthleteInterface,
  MatchEnhancedInterface,
  AgeClassParamsInterface,
  MatchScoresInterface,
} from '../../schemas';
import {
  defaultAthlete,
  default_params,
  default_scores,
} from './common/default_match_states';
import LoadingPage from './LoadingPage';
import MatchTimerLoaded from './MatchTimerLoaded';

// TODO in scores esiste il campo final time che va settato alla fine del match !!!
export default function MatchTimer() {
  // states of data from server
  const [params, setParams] = useState<AgeClassParamsInterface>(default_params);
  const max_shido = 3;
  const [scores, setScores] = useState<MatchScoresInterface>(default_scores);
  const [category_name, setCategoryName] = useState('Amichevole');
  const [white_athlete, setWhiteAthlete] = useState<AthleteInterface>(
    defaultAthlete('Bianco')
  );
  const [red_athlete, setRedAthlete] = useState<AthleteInterface>(
    defaultAthlete('Rosso')
  );

  // show the page only when the data is ready
  const [show_main_page, setShowMainPage] = useState(false);

  // if we have a match id, we get data from the server. If error, we go back to tournaments
  // if we don't have a match id, we don't change the default values and keep a friendly match
  const match_id = useParams().matchId;
  useEffect(() => {
    // friendly match
    if (!match_id) {
      setShowMainPage(true);
      return;
    }
    apiGet(`matches/${match_id}`)
      .then((match_data: MatchEnhancedInterface) => {
        inizializeStates(match_data);
        setShowMainPage(true);
      })
      .catch(() => {
        // if error searching the match, we go back
        turnBack();
      });
  }, [match_id]);

  function inizializeStates(match_data: MatchEnhancedInterface) {
    setCategoryName(match_data.category_name);
    setScores(match_data.scores);
    setParams(match_data.params);
    setRedAthlete(match_data.red_athlete as AthleteInterface);
    setWhiteAthlete(match_data.white_athlete as AthleteInterface);
  }

  // navigate back
  const navigate = useNavigate();
  const [search_params] = useSearchParams();
  const tournament = search_params.get('from_tournament');

  /** return to the tournaments page */
  function turnBack() {
    if (tournament) {
      navigate(`/tournament?from_tournament=${tournament}`);
    } else navigate('/tournament');
  }

  // return
  if (show_main_page) {
    return (
      <MatchTimerLoaded
        params={params}
        max_shido={max_shido}
        scores={scores}
        setScores={setScores}
        category_name={category_name}
        red_athlete={red_athlete}
        white_athlete={white_athlete}
        match_id={match_id}
        turnBack={turnBack}
      />
    );
  }
  return <LoadingPage />;
}
