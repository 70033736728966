import React from 'react';
import {
  MatchScoresInterface,
  AgeClassParamsInterface,
} from '../../../schemas';
import ScoreShido from './ScoreShido';
import ScoreSingle from './ScoreSingle';

/**
 * shows the scores in a row.
 * Each score element has the possibility of increase and decrease its corresponding score by
 * clicking on them.
 */
export default function ScoreRow(props: {
  scores: MatchScoresInterface;
  setSingleScore: (
    field: keyof MatchScoresInterface,
    max_value: number,
    increase: number
  ) => void;
  params: AgeClassParamsInterface;
  max_shido: number;
}) {
  const { scores, setSingleScore, params, max_shido } = props;

  return (
    <>
      <ScoreShido
        score={scores.white_penalties}
        setScore={(increase: number) =>
          setSingleScore('white_penalties', max_shido, increase)
        }
      />
      <ScoreSingle
        score={scores.white_ippon}
        setScore={(increase: number) =>
          setSingleScore('white_ippon', params.ippon_to_win, increase)
        }
        score_name={'Ippon'}
      />
      <ScoreSingle
        score={scores.white_wazaari}
        setScore={(increase: number) =>
          setSingleScore('white_wazaari', params.wazaari_to_win, increase)
        }
        score_name={'Waza Ari'}
      />
      <ScoreSingle
        score={scores.red_ippon}
        setScore={(increase: number) =>
          setSingleScore('red_ippon', params.ippon_to_win, increase)
        }
        score_name={'Ippon'}
      />
      <ScoreSingle
        score={scores.red_wazaari}
        setScore={(increase: number) =>
          setSingleScore('red_wazaari', params.wazaari_to_win, increase)
        }
        score_name={'Waza Ari'}
      />
      <ScoreShido
        score={scores.red_penalties}
        setScore={(increase: number) =>
          setSingleScore('red_penalties', max_shido, increase)
        }
      />
    </>
  );
}
