import React, { useContext, useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import {
  AthleteInterface,
  NewAthleteInterface,
  CompetitionInterface,
} from '../../../schemas';
import InputRow from '../../../Components/Inputs/InputRow';
import TwoOptionRadio from '../../../Components/Inputs/TwoOptionRadio';
import { apiGet } from '../../../Services/Api/api';
import { AuthContext } from '../../../Services/Auth/AuthContext';
import { useNavigate } from 'react-router-dom';

export default function AthleteForm(props: {
  handleClose: () => void;
  updateAthleteFromTable: (new_athletes: AthleteInterface[]) => void;
  initial_params?: NewAthleteInterface;
  apiSend: (params: NewAthleteInterface) => Promise<any>;
}) {
  const { handleClose, updateAthleteFromTable, initial_params, apiSend } =
    props;

  const [clubs, setClubs] = useState<string[]>([]);
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  useEffect(() => {
    if (!user?.competition) return navigate('/');
    const competition: CompetitionInterface =
      user.competition as CompetitionInterface;
    apiGet(`${competition.slug}/club`).then((clubData: string[]) =>
      setClubs(clubData)
    );
  }, []);

  const [params, setParams] = useState<NewAthleteInterface>({
    name: initial_params?.name || '',
    surname: initial_params?.surname || '',
    club: initial_params?.club || '',
    club_code: initial_params?.club_code || '',
    birth_year: initial_params?.birth_year || 0,
    birth_date: '',
    weight: initial_params?.weight || 0,
    gender: initial_params?.gender || 'M',
    present: true,
    weighted: true,
    belt_color: 'Bianca',
  });

  function getOnChangeFunction(field: keyof typeof params) {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      const { type, value } = e.target;
      return setParams((prevParams) => ({
        ...prevParams,
        ...{
          [field]: type === 'number' ? parseInt(e.target.value, 10) : value,
        },
      }));
    };
  }

  return (
    <form
      id='athlete-form'
      onSubmit={(e) => {
        e.preventDefault();
        if (
          !params.name ||
          !params.surname ||
          !params.club ||
          !params.club_code ||
          !params.birth_year ||
          !params.weight
        ) {
          return Swal.fire('Completare tutti i campi', '', 'warning');
        }
        if (!params.gender) {
          return Swal.fire("Scegliere il sesso dell'atleta", '', 'warning');
        }

        params.birth_date = `${params.birth_year}-01-01`;

        apiSend(params).then((new_athletes: AthleteInterface[]) => {
          updateAthleteFromTable(new_athletes);
          Swal.fire(
            'Operazione completata con successo',
            undefined,
            'success'
          ).then(() => handleClose());
        });
      }}
    >
      <InputRow
        value={params.name}
        onChange={getOnChangeFunction('name')}
        type={'text'}
      >
        {'Nome'}
      </InputRow>
      <InputRow
        value={params.surname}
        onChange={getOnChangeFunction('surname')}
        type={'text'}
      >
        {'Cognome'}
      </InputRow>
      <InputRow
        value={params.club}
        onChange={getOnChangeFunction('club')}
        type={'text'}
        datalist={clubs}
      >
        {"Societa'"}
      </InputRow>
      <InputRow
        value={params.club_code}
        onChange={getOnChangeFunction('club_code')}
        type={'text'}
      >
        {"Codice Societa'"}
      </InputRow>
      <InputRow
        value={params.birth_year}
        onChange={getOnChangeFunction('birth_year')}
        type={'number'}
        min={1900}
        max={new Date().getFullYear()}
      >
        {'Anno Nascita'}
      </InputRow>
      <InputRow
        value={params.weight}
        onChange={getOnChangeFunction('weight')}
        type={'number'}
        min={1}
        max={200}
      >
        {'Peso'}
      </InputRow>
      <TwoOptionRadio
        first_option={'M'}
        second_option={'F'}
        onChange={(option: string) => {
          if (option !== 'M' && option !== 'F') return;
          setParams((prevParams) => ({ ...prevParams, ...{ gender: option } }));
        }}
        value={params.gender}
      >
        Sesso
      </TwoOptionRadio>
      <button className='timer-button orange' type='submit' form='athlete-form'>
        Salva
      </button>
    </form>
  );
}
