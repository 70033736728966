import React from 'react';
import './Buttons.css';
import { ButtonPropsInterface } from './IconButton';

/** small, outlined transparent button */
export default function OutlinedButton(props: ButtonPropsInterface) {
  const { onClick, style = 'primary', type, children, disabled } = props;

  return (
    <button
      onClick={onClick}
      type={type}
      disabled={disabled}
      className={`${style} border rounded p-2`}
    >
      {children}
    </button>
  );
}
