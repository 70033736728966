import React from 'react';
import { MatchInterface } from '../../../schemas';
import Match from './Match';

export default function Round(props: { round: MatchInterface[] }) {
  const { round } = props;

  const match_elem = round.map((match, matchIdx) => {
    return <Match match={match} key={matchIdx}></Match>;
  });
  return <div className='flex flex-col'>{match_elem}</div>;
}
