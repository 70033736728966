import React, { useContext } from 'react';
import { FaSun, FaMoon } from 'react-icons/fa';
import { storeTheme, ThemeT } from '../../Services/Theme/theme-service';
import { ThemeContext } from '../../Services/Theme/ThemeContext';

/** sun/moon button left of the navbar, to change to dark mode or light mode */
export default function DarkModeToggle () {
  const { theme, setTheme } = useContext(ThemeContext);

  function getIcon () {
    return theme === 'dark'
      ? <FaSun></FaSun>
      : <FaMoon></FaMoon>;
  }

  function toggleTheme() {
    const new_theme: ThemeT = theme === 'dark'
      ? 'light'
      : 'dark';
    setTheme(new_theme);
    storeTheme(new_theme);
  }

  return (
    <button onClick={() => toggleTheme()}>
      {getIcon()}
    </button>
  );
}
