import React, { useState } from 'react';
import { AthleteInterface } from '../../../schemas';
import AthleteRow from './AthleteRow';
import CategoryRow from './CategoryRow';
import NoAthletesRow from './NoAthletesRow';

export default function CategorySubTable(props: {
  athletes: AthleteInterface[];
  updateAthleteFromTable: (new_athletes: AthleteInterface[]) => void;
  deleteAthleteFromTable: (athleteToDelete: AthleteInterface) => void;
  modifications_disabled: boolean;
  heavyweights_name: string;
}) {
  const {
    athletes,
    updateAthleteFromTable,
    deleteAthleteFromTable,
    modifications_disabled,
    heavyweights_name,
  } = props;
  const [opened, setOpened] = useState(true);

  /** get each Athlete of a Category */
  function getTableAthletes() {
    if (!athletes || athletes.length === 0) return <NoAthletesRow />;

    return athletes.map((athlete) => (
      <AthleteRow
        athlete={athlete}
        updateAthleteFromTable={updateAthleteFromTable}
        deleteAthleteFromTable={deleteAthleteFromTable}
        key={athlete._id}
        modifications_disabled={modifications_disabled}
      />
    ));
  }

  return (
    <>
      <CategoryRow
        openChevron={() => setOpened((prev) => !prev)}
        opened={opened}
        heavyweights_name={heavyweights_name}
      />
      {opened && getTableAthletes()}
    </>
  );
}
