import React from 'react';

/**
 * It renders two radio buttons with labels and a text.
 * A radio button (or option button) is a graphical control element that allows the user to
 * choose only one of a predefined set of mutually exclusive options.
 * @param props
 * @returns A function that returns a div with two radio buttons.
 */
export default function TwoOptionRadio(props: {
  /** object next to the radio options */
  children: React.ReactNode;
  /** value and name of the first option */
  first_option: string;
  /** value and name of the second option */
  second_option: string;
  /** function called when clicking on a radio. should change props.value to option */
  onChange: (option: string) => void;
  /** the current selected value */
  value: string;
}) {
  const { children, first_option, second_option, onChange, value } = props;

  return (
    <div className='select-gender'>
      <div className='gender-text'>{children}</div>
      <input
        id={first_option}
        type='radio'
        className='radio-input'
        name='gender'
        onChange={() => onChange(first_option)}
        checked={value === first_option}
      />
      <label className='timer-label radio-label' htmlFor={first_option}>
        {first_option}
      </label>
      <input
        id={second_option}
        type='radio'
        className='radio-input'
        name='gender'
        onChange={() => onChange(second_option)}
        checked={value === second_option}
      />
      <label className='timer-label radio-label' htmlFor={second_option}>
        {second_option}
      </label>
    </div>
  );
}
