import React from 'react';

/**
 * It's a React component that renders an input element with some default styling
 * @param props
 * @returns A React component.
 */
export default function Input(props: {
  /** specify the type of the input */
  type?: string;
  /** placeholder that informs about the input field */
  placeholder: string;
  /** the value of the input field */
  value: string;
  /** function that changes props.value based on the event */
  onChange: React.ChangeEventHandler<HTMLInputElement>;
}) {
  const { type, placeholder, value, onChange } = props;

  return (
    <input
      className='w-full bg-white dark:bg-transparent border border-neutral-400 dark:border-neutral-500 rounded p-2'
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    />
  );
}
