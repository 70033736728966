import React from 'react';
import DisplayedTimer from './DisplayedTimer';

/** returns DisplayedTimer if show_osk_timer is true */
export default function OskDisplayedTimer(props: {
  show_osk_timer: boolean;
  seconds: number;
  is_running: boolean;
  playDisplayedTimer: () => void;
}) {
  const { show_osk_timer, seconds, is_running, playDisplayedTimer } = props;

  if (show_osk_timer) {
    return (
      <DisplayedTimer
        seconds={seconds}
        is_running={is_running}
        playDisplayedTimer={playDisplayedTimer}
        is_osk_timer={true}
      />
    );
  } else return <div id='match-timer-container' />;
}
