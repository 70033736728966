import React from 'react';
import Player from './Player';
import Round from './Round';
import '../Bracket.css';
import { AthleteInterface, MatchInterface } from '../../../schemas';

export function getWinnerAthlete(match: MatchInterface) {
  if (match == null || match.winner_athlete == null) {
    return null;
  }
  const white_athlete = match.white_athlete as AthleteInterface;
  const red_athlete = match.red_athlete as AthleteInterface;

  if (white_athlete && match.winner_athlete === white_athlete._id) {
    return white_athlete;
  }

  if (red_athlete && match.winner_athlete === red_athlete._id) {
    return red_athlete;
  }

  return null;
}

export default function Bracket(props: {
  bracket: MatchInterface[][];
  compact: boolean;
}) {
  const { bracket, compact } = props;

  const final_match = bracket[bracket.length - 1][0];

  const winner = getWinnerAthlete(final_match);

  const rounds_elem = bracket.map((round, round_index) => {
    return <Round key={round_index} round={round}></Round>;
  });

  return (
    <div className='overflow-x-auto'>
      <div className={`flex bracket ${compact ? 'compact' : ''}`}>
        {rounds_elem}
        <div className='flex items-center'>
          <Player
            player={winner}
            state={winner !== null ? 'won' : 'tbd'}
          ></Player>
        </div>
      </div>
    </div>
  );
}
