import {
  AthleteInterface,
  AgeClassParamsInterface,
  MatchScoresInterface,
} from '../../../schemas';

export const default_params: AgeClassParamsInterface = {
  match_time: 4 * 60,
  supplemental_match_time: Infinity,
  ippon_to_win: 1,
  wazaari_to_win: 2,
  ippon_timer: 20,
  wazaari_timer: 10,
};

export const default_scores: MatchScoresInterface = {
  final_time: 0,
  white_ippon: 0,
  white_wazaari: 0,
  white_penalties: 0,
  red_ippon: 0,
  red_wazaari: 0,
  red_penalties: 0,
};

export function defaultAthlete(color: string) {
  const name_string = `Atleta ${color}`;
  const created_at = new Date();
  const default_athlete: AthleteInterface = {
    _id: name_string,
    name: '',
    surname: name_string,
    competition: '',
    club: '',
    club_code: '',
    gender: 'M',
    weight: 0,
    birth_year: 0,
    birth_date: '',
    present: true,
    weighted: true,
    belt_color: 'Bianca',
    category: '',
    created_at,
    last_updated: created_at,
  };
  return default_athlete;
}
