import React from 'react';
import { AthleteColor } from '../common/AthleteColor';

/** uses css to display a bar that shows the osaekomi timer */
export default function OskBar(props: {
  has_osk: AthleteColor;
  seconds: number;
  max_time: number;
}) {
  const { has_osk, seconds, max_time } = props;

  let bar_css_id = '';
  switch (has_osk) {
    case 'RED':
      bar_css_id = 'red-osk-bar';
      break;
    case 'WHITE':
      bar_css_id = 'white-osk-bar';
      break;
    default:
      return <div className='osk-bar' />;
  }

  return (
    <div
      className='osk-bar'
      id={bar_css_id}
      style={
        {
          '--width': (seconds / max_time) * 100,
        } as React.CSSProperties
      }
    />
  );
}
