import React, { useContext, useEffect, useState } from 'react';
import GSSymbol from './components/GSSymbol';
import InfoButton from './components/InfoButton';
import DisplayedTimer from './components/DisplayedTimer';
import MatchName from './components/MatchName';
import OskRow from './components/OskRow';
import ScoreRow from './components/ScoreRow';
import LastRow from './components/LastRow';
import { useStopwatch, useTimer } from 'react-timer-hook';
import { checkAdvantage, checkWinner } from './common/checkWinner';
import {
  AgeClassParamsInterface,
  MatchScoresInterface,
  AthleteInterface,
  WinningDataInterface,
} from '../../schemas';
import { AthleteColor } from './common/AthleteColor';
import { getToken } from '../../Services/Auth/token-service';
import { useNavigate } from 'react-router-dom';
import { apiPost } from '../../Services/Api/api';
import Swal from 'sweetalert2';
import { SecondWindowContext } from '../../Services/SecondWindow/SecondWindowContext';
import { SecondMonitorMsg } from '../MatchTimerSecondMonitor/MatchTimerSecondMonitor';

export default function MatchTimerLoaded(props: {
  params: AgeClassParamsInterface;
  max_shido: number;
  scores: MatchScoresInterface;
  setScores: React.Dispatch<React.SetStateAction<MatchScoresInterface>>;
  category_name: string;
  red_athlete: AthleteInterface;
  white_athlete: AthleteInterface;
  match_id: string | undefined;
  turnBack: () => void;
}) {
  const {
    params,
    max_shido,
    scores,
    setScores,
    category_name,
    red_athlete,
    white_athlete,
    match_id,
    turnBack,
  } = props;

  const { second_window } = useContext(SecondWindowContext);

  const [winner, setWinner] = useState<AthleteColor>('');
  const [is_gs, setIsGs] = useState(false);
  // we want to show the osk timer until we pause the main timer and then play it again
  const [show_osk_timer, setShowOskTimer] = useState(false);
  const [has_osk, setHasOsk] = useState<AthleteColor>('');

  // check for winner when scores change
  useEffect(() => {
    const { is_white_winning, is_red_winning } = checkWinner(
      scores,
      params,
      max_shido,
      is_gs
    );

    if (is_white_winning && is_red_winning) return setWinner('ERROR');
    if (is_white_winning) return setWinner('WHITE');
    if (is_red_winning) return setWinner('RED');
    return setWinner('');
  }, [scores]);

  /** change a single field of scores, keeping consideration of its max possible value to avoid overflow */
  const setSingleScore = (
    field: keyof MatchScoresInterface,
    max_value: number,
    increase: number
  ) =>
    setScores((prev_scores) => {
      const new_value = prev_scores[field] + increase;

      if (new_value < 0) return prev_scores;
      if (new_value > max_value) return prev_scores;

      const new_scores = { ...prev_scores };
      new_scores[field] = new_value;
      return new_scores;
    });

  // main timer
  const {
    seconds: main_seconds,
    minutes: main_minutes,
    isRunning: main_is_running,
    pause: mainPause,
    resume: mainResume,
    // start: mainStart,
    // restart: mainRestart,
  } = useTimer({
    expiryTimestamp: (() => {
      const time = new Date();
      time.setSeconds(
        time.getSeconds() + params.match_time - scores.final_time
      );
      return time;
    })(),
    onExpire: mainTimeEnds,
    autoStart: false,
  });

  // golden score timer (in this case, a stopwatch)
  const {
    seconds: gs_seconds,
    minutes: gs_minutes,
    isRunning: gs_is_running,
    pause: gsPause,
    start: gsStart,
    // reset: gsReset,
  } = useStopwatch({ autoStart: false });

  const {
    seconds: osk_seconds,
    isRunning: osk_is_running,
    pause: oskPause,
    start: oskStart,
    reset: oskReset,
  } = useStopwatch({ autoStart: false });

  // update the second monitor
  useEffect(() => {
    const msg: SecondMonitorMsg = {
      msg_type: 'match_data',
      match_data: {
        scores,
        white_athlete,
        red_athlete,
        winner,
        is_gs,
        show_osk_timer,
        has_osk,
        seconds: (is_gs && gs_seconds) || main_seconds,
        minutes: (is_gs && gs_minutes) || main_minutes,
        is_running: (is_gs && gs_is_running) || main_is_running,
        category_name,
        osk_seconds,
        osk_is_running,
        osk_max_time: params.ippon_timer,
      },
    };
    second_window?.postMessage(JSON.stringify(msg));
  }, [
    scores,
    winner,
    is_gs,
    show_osk_timer,
    has_osk,
    main_seconds,
    gs_seconds,
    osk_seconds,
  ]);

  // we do not have an expire function on the stopwatch
  useEffect(() => {
    if (gs_minutes * 60 + gs_seconds >= params.supplemental_match_time) {
      gsTimeEnds();
    }
  }, [gs_seconds]);
  // we can't start the stopwatch if we're over the time limit
  function gsResume() {
    if (gs_minutes * 60 + gs_seconds >= params.supplemental_match_time) return;
    gsStart();
  }

  /** called when the main timer reaches zero. If yet no advantage, we go to golden score */
  function mainTimeEnds() {
    // play sound

    const { is_white_winning, is_red_winning } = checkAdvantage(scores);
    if (is_white_winning) return setWinner('WHITE');
    if (is_red_winning) return setWinner('RED');

    setIsGs(true);
  }

  /** called when the golden score timer ends. If we arrived here, there is no winner and we ask the user */
  function gsTimeEnds() {
    gsPause();
    // TODO ask the user the winner
    setWinner('ERROR');
  }

  /**
   * function that modifies scores.final_time, needed for api return.
   * Possible bug: this function gets called when we switch the main timer.
   * Check that we can't finish the match when the timer is still on.
   */
  function setFinalTime() {
    const final_time = (() => {
      // total time + extra gs time = passed time
      if (is_gs) return params.match_time + (gs_minutes * 60 + gs_seconds);
      // total time - times that remains = passed time
      return params.match_time - (main_minutes * 60 + main_seconds);
    })();

    setScores((prev_scores) => {
      const new_scores = { ...prev_scores };
      new_scores.final_time = final_time;
      return new_scores;
    });
  }

  /** select what to do when clicking on the main timer */
  function playDisplayedTimer() {
    setFinalTime();

    if (is_gs) {
      if (gs_is_running) return gsPause();
      return gsResume();
    }

    if (main_is_running) return mainPause();
    else {
      setShowOskTimer(false);
      return mainResume();
    }
  }

  /** when the osaekomi ends (via button or reached ippon time limit), we see if we can give point to someone */
  function oskTimeEnds(owner: AthleteColor, time: number) {
    if (time >= params.ippon_timer) {
      if (owner === 'WHITE') {
        setSingleScore('white_ippon', params.ippon_to_win, 1);
      }
      if (owner === 'RED') setSingleScore('red_ippon', params.ippon_to_win, 1);
    } else if (time >= params.wazaari_timer) {
      if (owner === 'WHITE') {
        setSingleScore('white_wazaari', params.wazaari_to_win, 1);
      }
      if (owner === 'RED') {
        setSingleScore('red_wazaari', params.wazaari_to_win, 1);
      }
    }
  }

  const navigate = useNavigate();

  function finishMatch() {
    // we need the timer to be paused to end the match
    if (main_is_running) return; // TODO swal Error?

    // the user is not logged and it is using a free version
    const is_user_logged = !!getToken();
    if (!is_user_logged) {
      // TODO substitute to a personalized modal
      return Swal.fire('Grazie di aver usato Judo in Cloud').then(() =>
        navigate(0)
      );
    }

    // friendly match
    if (!match_id) return turnBack();

    const winner_athlete: string = (() => {
      if (winner === 'RED') return red_athlete._id as string;
      if (winner === 'WHITE') return white_athlete._id as string;
      // the button that uses finishMatch() is aviable only when winner is either RED or WHITE
      // because otherwise the last row does not show it
      throw new Error('How did you click here without having a valid winner?');
    })();

    const body: WinningDataInterface = {
      winner_athlete,
      scores,
    };
    apiPost(`matches/${match_id}`, body).then(() => {
      const msg: SecondMonitorMsg = {
        msg_type: 'wait',
      };
      second_window?.postMessage(JSON.stringify(msg));
      turnBack();
    });
  }

  return (
    <div
      className='wrapper bg-white dark:bg-neutral-800'
      tabIndex={0}
      onContextMenu={(e) => e.preventDefault()}
    >
      <div className='half-colored-background'></div>
      <div className='grid-container'>
        <InfoButton />
        <GSSymbol is_gs={is_gs} />
        <DisplayedTimer
          seconds={(is_gs && gs_seconds) || main_seconds}
          minutes={(is_gs && gs_minutes) || main_minutes}
          is_running={(is_gs && gs_is_running) || main_is_running}
          playDisplayedTimer={playDisplayedTimer}
        />
        <MatchName name={category_name} />

        <ScoreRow
          scores={scores}
          setSingleScore={setSingleScore}
          params={params}
          max_shido={max_shido}
        />
        <OskRow
          oskTimeEnds={oskTimeEnds}
          max_time={params.ippon_timer}
          show_osk_timer={show_osk_timer}
          setShowOskTimer={setShowOskTimer}
          has_osk={has_osk}
          setHasOsk={setHasOsk}
          seconds={osk_seconds}
          is_running={osk_is_running}
          pause={oskPause}
          start={oskStart}
          reset={oskReset}
        />
        <LastRow
          winner={winner}
          white_athlete={white_athlete}
          red_athlete={red_athlete}
          finishMatch={finishMatch}
        />
      </div>
    </div>
  );
}
