import React from 'react';
import { AthleteColor } from '../../MatchTimer/common/AthleteColor';
import OskBar from '../../MatchTimer/components/OskBar';
import OskDisplayedTimer from '../../MatchTimer/components/OskDisplayedTimer';

export default function SecondMonitorOskRow(props: {
  max_time: number;
  show_osk_timer: boolean;
  has_osk: AthleteColor;
  seconds: number;
  is_running: boolean;
}) {
  const { max_time, show_osk_timer, has_osk, seconds, is_running } = props;

  const no_osk = (
    <>
      <div style={{ gridColumnEnd: 'span 2' }}></div>{' '}
      <OskDisplayedTimer
        show_osk_timer={show_osk_timer}
        seconds={seconds}
        is_running={is_running}
        playDisplayedTimer={() => {}}
      />
      <div style={{ gridColumnEnd: 'span 2' }}></div>{' '}
    </>
  );

  const red_osk = (
    <>
      <div style={{ gridColumnEnd: 'span 2' }}></div>
      <OskDisplayedTimer
        show_osk_timer={show_osk_timer}
        seconds={seconds}
        is_running={is_running}
        playDisplayedTimer={() => {}}
      />
      <OskBar has_osk={has_osk} seconds={seconds} max_time={max_time} />
    </>
  );

  const white_osk = (
    <>
      <OskBar has_osk={has_osk} seconds={seconds} max_time={max_time} />
      <OskDisplayedTimer
        show_osk_timer={show_osk_timer}
        seconds={seconds}
        is_running={is_running}
        playDisplayedTimer={() => {}}
      />
      <div style={{ gridColumnEnd: 'span 2' }}></div>
    </>
  );

  switch (has_osk) {
    case '':
      return no_osk;
    case 'WHITE':
      return white_osk;
    case 'RED':
      return red_osk;
    case 'ERROR':
    default:
      return <></>;
  }
}
