import React from 'react';

/** shows an error message in the last row when there is no clear winner */
export default function LastRowError() {
  return (
    <div className='name-row-flexbox'>
      <div id='winner-sign' />
      <div id='winner-name'>Errore: entrambi gli atleti vincono</div>
      <div id='winner-sign' />
    </div>
  );
}
