import React from 'react';
import { AthleteInterface, TournamentRankingInterface } from '../../../schemas';

export default function SimoneNewRanking(props: {
  ranking: TournamentRankingInterface[];
  athletes: AthleteInterface[];
}) {
  const { ranking, athletes } = props;

  function getTableRow(r: TournamentRankingInterface) {
    function findName(athlete_id: string) {
      const athlete = athletes.find((a) => a._id === athlete_id);
      return `${athlete?.surname} ${athlete?.name}`;
    }

    return (
      <tr key={r.athlete}>
        <td className='table-column-15'>{r.position}</td>
        <td className='table-column-85'>{findName(r.athlete)}</td>
      </tr>
    );
  }

  return (
    <div className='table-container'>
      <div className='table-text'>Classifica Finale</div>
      <table className='table'>
        <thead>
          <tr>
            <td className='table-column-15'>Posizione</td>
            <td className='table-column-85'>Atleta</td>
          </tr>
        </thead>
        <tbody style={{ height: '100%' }}>
          {ranking.map((r) => getTableRow(r))}
        </tbody>
      </table>
    </div>
  );
}
