import React from 'react';
import { AthleteInterface } from '../../../schemas';

/** shows the names of the athletes */
export default function LastRowName(props: {
  white_athlete: AthleteInterface;
  red_athlete: AthleteInterface;
}) {
  const { white_athlete, red_athlete } = props;

  return (
    <div className='name-row-flexbox'>
      <div className='athlete-name' id='white-name'>
        {`${white_athlete.surname} ${white_athlete.name}`}
      </div>
      <div className='athlete-club' id='white-club'>
        {white_athlete.club}
      </div>
      <div className='vertical-black-line'></div>
      <div className='athlete-name' id='red-name'>
        {`${red_athlete.surname} ${red_athlete.name}`}
      </div>
      <div className='athlete-club' id='red-club'>
        {red_athlete.club}
      </div>
    </div>
  );
}
