import React, { useState } from 'react';
import Swal from 'sweetalert2';
import { apiPost, apiPut } from '../../../Services/Api/api';
import { AgeClassEnhancedInterface, AgeClassInterface } from '../../../schemas';
import InputRow from '../../../Components/Inputs/InputRow';
import InputToggle from '../../../Components/Inputs/InputToggle';

export default function AgeClassForm(props: {
  handleClose: () => void;
  age_class: AgeClassEnhancedInterface;
  updateAgeClassFromTable: (newAgeClass: AgeClassInterface) => void;
}) {
  const { handleClose, age_class, updateAgeClassFromTable } = props;
  const age_class_id = age_class._id;
  const [params, setParams] = useState(age_class.params);
  const [closed, setClosed] = useState(age_class.closed);
  if (!age_class) handleClose();

  function getOnChangeFunction(field: keyof typeof params) {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      let new_value = e.target.value;
      if (field === 'supplemental_match_time' && new_value === '') {
        new_value = 'Infinity';
      }
      setParams((prevParams) => ({
        ...prevParams,
        ...{ [field]: new_value },
      }));
    };
  }

  return (
    <form
      id='athlete-form'
      onSubmit={(e) => {
        e.preventDefault();
        if (
          !params.match_time ||
          !params.ippon_timer ||
          !params.ippon_to_win ||
          !params.wazaari_timer ||
          !params.wazaari_to_win
        ) {
          return Swal.fire(
            'Completare tutti i campi',
            'Ad esclusione del campo <i>Tempo Golden Score</i>',
            'warning'
          );
        }

        apiPut(`age_classes/params/${age_class_id}`, params).then(
          (result: AgeClassInterface) => {
            const new_age_class: AgeClassEnhancedInterface = result;
            new_age_class.categories = age_class.categories;
            updateAgeClassFromTable(result);
            Swal.fire(
              'Operazione completata con successo',
              undefined,
              'success'
            ).then(() => handleClose());
          }
        );

        if (closed) {
          apiPost(`age_classes/close/${age_class_id}`, {}).then(
            (result: AgeClassInterface) => {
              const new_age_class: AgeClassEnhancedInterface = result;
              new_age_class.categories = age_class.categories;
              updateAgeClassFromTable(result);
              Swal.fire(
                'Operazione completata con successo',
                undefined,
                'success'
              ).then(() => handleClose());
            }
          );
        }

        /* apiPost(`age_classes/${age_class_id}`, { closed, params }).then(
          (result: AgeClassInterface) => {
            result.categories = age_class.categories;
            updateAgeClassFromTable(result);
            Swal.fire(
              'Operazione completata con successo',
              undefined,
              'success'
            ).then(() => handleClose());
          }
        ); */
      }}
    >
      <InputRow
        value={params.match_time}
        onChange={getOnChangeFunction('match_time')}
        type={'number'}
        min={0}
      >
        {'Tempo Regolamentare'}
      </InputRow>
      <InputRow
        value={params.supplemental_match_time}
        onChange={getOnChangeFunction('supplemental_match_time')}
        type={'number'}
        min={0}
      >
        {'Tempo Golden Score'}
      </InputRow>
      (lasciare vuoto per golden score illimitato)
      <InputRow
        value={params.ippon_to_win}
        onChange={getOnChangeFunction('ippon_to_win')}
        type={'number'}
        min={0}
      >
        {'Ippon per Vincere'}
      </InputRow>
      {/* <InputRow
        value={params.wazaari_to_win}
        onChange={getOnChangeFunction('wazaari_to_win')}
        type={'number'}
        min={0}
      >
        {'Waza Ari per Vincere'}
      </InputRow> */}
      <InputRow
        value={params.ippon_timer}
        onChange={getOnChangeFunction('ippon_timer')}
        type={'number'}
        min={0}
      >
        {'Timer Osaekomi Ippon'}
      </InputRow>
      <InputRow
        value={params.wazaari_timer}
        onChange={getOnChangeFunction('wazaari_timer')}
        type={'number'}
        min={0}
      >
        {'Timer Osaekomi Waza Ari'}
      </InputRow>
      <InputToggle
        checked={closed}
        onChange={() => {
          Swal.fire({
            title: "Chiudere la classe d'eta'?",
            text: "Verrano creati gli incontri, ma non sara' piu' possibile inserire atleti di questa classe",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: "Si', chiudi la classe d'eta'",
            cancelButtonText: 'No, torna indietro ',
          }).then((result) => {
            if (result.isConfirmed) {
              apiPost(
                `age_classes/${closed ? 'reopen' : 'close'}/${age_class_id}`,
                {}
              ).then((age_class: AgeClassInterface) => {
                setClosed(age_class.closed);
              });
            }
          });
        }}
      >
        {"Chiudere la classe d'eta'?"}
      </InputToggle>
      <button className='timer-button orange' type='submit' form='athlete-form'>
        Salva
      </button>
    </form>
  );
}
