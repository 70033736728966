import React from 'react';
import { AthleteInterface } from '../../../schemas';
import { AthleteColor } from '../common/AthleteColor';
import LastRowError from './LastRowError';
import LastRowName from './LastRowName';
import LastRowWinner from './LastRowWinner';

/** based on the winner, selects the right last row component */
export default function LastRow(props: {
  winner: AthleteColor;
  white_athlete: AthleteInterface;
  red_athlete: AthleteInterface;
  finishMatch?: () => void;
}) {
  const { winner, white_athlete, red_athlete, finishMatch } = props;

  switch (winner) {
    case '':
      return (
        <LastRowName white_athlete={white_athlete} red_athlete={red_athlete} />
      );
    case 'ERROR':
      return <LastRowError />;
    case 'WHITE':
      return <LastRowWinner winner_athlete={white_athlete} finishMatch={finishMatch}/>;
    case 'RED':
      return <LastRowWinner winner_athlete={red_athlete} finishMatch={finishMatch}/>;
  }
}
