import React, { useState } from 'react';
import { FaPen, FaTrash } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { apiDelete, apiPut } from '../../../Services/Api/api';
import { AthleteInterface, NewAthleteInterface } from '../../../schemas';
import AthleteFormModal from './AthleteFormModal';

export default function AthleteRow(props: {
  athlete: AthleteInterface;
  updateAthleteFromTable: (new_athletes: AthleteInterface[]) => void;
  deleteAthleteFromTable: (athleteToDelete: AthleteInterface) => void;
  modifications_disabled: boolean;
}) {
  const {
    athlete,
    updateAthleteFromTable,
    deleteAthleteFromTable,
    modifications_disabled,
  } = props;
  const [is_modify_athlete_open, setIsModifyAthleteOpen] = useState(false);

  return (
    <tr>
      <td className='table-column-15'>{athlete.name}</td>
      <td className='table-column-15'>{athlete.surname}</td>
      <td className='table-column-15'>{athlete.club}</td>
      <td className='table-column-15'>{athlete.birth_year}</td>
      <td className='table-column-15'>{athlete.weight}</td>
      <td className='table-column-15'>{athlete.gender}</td>
      <td className='table-column-10 centered-text'>
        <button
          className='icon-button orange'
          disabled={modifications_disabled}
          onClick={() => setIsModifyAthleteOpen(true)}
        >
          <FaPen />
        </button>
        {is_modify_athlete_open && (
          <AthleteFormModal
            handleClose={() => setIsModifyAthleteOpen(false)}
            updateAthleteFromTable={updateAthleteFromTable}
            apiSend={(params: NewAthleteInterface) =>
              apiPut(`athletes/${athlete._id}`, params).then(
                (new_athlete: AthleteInterface) => {
                  updateAthleteFromTable([new_athlete]);
                  if (new_athlete.category !== athlete.category) {
                    deleteAthleteFromTable(athlete);
                  }
                }
              )
            }
            initial_params={athlete}
          >
            Modifica Atleta
          </AthleteFormModal>
        )}
        <button
          className='icon-button orange'
          disabled={modifications_disabled}
          onClick={() =>
            Swal.fire({
              title: 'Sei sicuro?',
              text: `Stai per eliminare l'atleta ${athlete.name} ${athlete.surname} della societa' ${athlete.club}`,
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: "Si', elimina",
              cancelButtonText: 'No, torna indietro',
            }).then((result) => {
              if (result.isConfirmed) {
                apiDelete(`athletes/${athlete._id}`).then(
                  (deletedAthlete: AthleteInterface) => {
                    deleteAthleteFromTable(deletedAthlete);
                    Swal.fire(
                      'Cancellato',
                      `l'atleta ${deletedAthlete.name} ${deletedAthlete.surname} e' stato eliminato dal sistema`,
                      'success'
                    );
                  }
                );
              }
            })
          }
        >
          <FaTrash />
        </button>
      </td>
    </tr>
  );
}
