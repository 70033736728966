import React from 'react';

/**
 * if is_gs is true, returns the GS text.
 * otherwise, placeholder for grid
 */
export default function GSSymbol(props: { is_gs: boolean }) {
  const { is_gs } = props;

  return <div id='gs-symbol'>{is_gs && 'GS'}</div>;
}
