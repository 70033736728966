import React, { useEffect, useState } from 'react';
import { AthleteInterface, MatchScoresInterface } from '../../schemas';
import { AthleteColor } from '../MatchTimer/common/AthleteColor';
import DisplayedTimer from '../MatchTimer/components/DisplayedTimer';
import GSSymbol from '../MatchTimer/components/GSSymbol';
import LastRow from '../MatchTimer/components/LastRow';
import MatchName from '../MatchTimer/components/MatchName';
import LoadingPage from '../MatchTimer/LoadingPage';
import SecondMonitorOskRow from './components/SecondMonitorOskRow';
import SecondMonitorScoreRow from './components/SecondMonitorScoreRow';

type SecondMonitorMsgWait = {
  msg_type: 'wait';
};

type SecondMonitorMsgMatchData = {
  msg_type: 'match_data';
  match_data: {
    scores: MatchScoresInterface;
    winner: AthleteColor;
    white_athlete: AthleteInterface;
    red_athlete: AthleteInterface;
    is_gs: boolean;
    show_osk_timer: boolean;
    has_osk: AthleteColor;
    seconds: number;
    minutes: number;
    is_running: boolean;
    category_name: string;
    osk_seconds: number;
    osk_is_running: boolean;
    osk_max_time: number;
  };
};

export type SecondMonitorMsg = SecondMonitorMsgWait | SecondMonitorMsgMatchData;

export default function MatchTimerSecondMonitor() {
  const [msg, setMsg] = useState<SecondMonitorMsg | null>(null);
  useEffect(() => {
    window.addEventListener('message', (e) => {
      const msg: SecondMonitorMsgMatchData = JSON.parse(e.data);
      if (!msg?.msg_type) return;
      setMsg(msg);
    });
  }, []);

  if (!msg || !msg.msg_type || msg.msg_type === 'wait') {
    // TODO custom logo
    return <LoadingPage />;
  } else {
    return (
      <div
        className='wrapper bg-white dark:bg-neutral-800'
        tabIndex={0}
        onContextMenu={(e) => e.preventDefault()}
      >
        <div className='half-colored-background'></div>
        <div className='grid-container'>
          <div></div>
          <GSSymbol is_gs={msg.match_data.is_gs} />
          <DisplayedTimer
            seconds={msg.match_data.seconds}
            minutes={msg.match_data.minutes}
            is_running={msg.match_data.is_running}
            playDisplayedTimer={() => {}}
          />
          <MatchName name={msg.match_data.category_name} />

          <SecondMonitorScoreRow scores={msg.match_data.scores} />
          <SecondMonitorOskRow
            max_time={msg.match_data.osk_max_time}
            show_osk_timer={msg.match_data.show_osk_timer}
            has_osk={msg.match_data.has_osk}
            is_running={msg.match_data.osk_is_running}
            seconds={msg.match_data.osk_seconds}
          />
          <LastRow
            winner={msg.match_data.winner}
            white_athlete={msg.match_data.white_athlete}
            red_athlete={msg.match_data.red_athlete}
          />
          <div></div>
        </div>
      </div>
    );
  }
}
