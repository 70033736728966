import React from 'react';
import {
  AthleteInterface,
  MatchInterface,
  TournamentEnhancedInterface,
} from '../../../schemas';
import { getWinnerAthlete } from '../components/Bracket';

export default function SimoneNewMatchTable(props: {
  tournament: TournamentEnhancedInterface;
}) {
  const { tournament } = props;

  function getTableRow(m: MatchInterface) {
    const white_athlete = m.white_athlete as AthleteInterface;
    const red_athlete = m.red_athlete as AthleteInterface;
    const winner_athlete = getWinnerAthlete(m);

    function getTableTD(a: AthleteInterface | null) {
      if (!a) return <td className='table-column-33'></td>;
      return <td className='table-column-33'>{`${a.surname} ${a.name}`}</td>;
    }

    return (
      <tr key={m._id}>
        {getTableTD(white_athlete)}
        {getTableTD(red_athlete)}
        {getTableTD(winner_athlete)}
      </tr>
    );
  }

  return (
    <div className='table-container'>
      <div className='table-text'>Lista Incontri</div>
      <table className='table'>
        <thead>
          <tr>
            <td className='table-column-33'>Atleta Bianco</td>
            <td className='table-column-33'>Atleta Rosso</td>
            <td className='table-column-33'>Vincitore</td>
          </tr>
        </thead>
        <tbody style={{ height: '100%' }}>
          {tournament.matches
            .filter((m) => !!m.white_athlete && !!m.red_athlete)
            .map((m) => getTableRow(m))}
        </tbody>
      </table>
    </div>
  );
}
