import React from 'react';
import { AthleteInterface } from '../../../schemas';

export default function Player(props: {
  player: AthleteInterface | null;
  state: 'won' | 'lost' | 'tbd';
}) {
  const { player, state } = props;

  return (
    <div
      className={`player border-2 flex justify-center items-center ${state}`}
    >
      {player?.name} {player?.surname}
    </div>
  );
}
