import React, { useState } from 'react';
import {
  AthleteInterface,
  AgeClassInterface,
  AgeClassEnhancedInterface,
} from '../../../schemas';
import { AthletesPerCategory } from '../AthletesPage';
import AgeClassRow from './AgeClassRow';
import CategorySubTable from './CategorySubTable';

export default function AgeClassSubTable(props: {
  age_class: AgeClassEnhancedInterface;
  athletes: AthletesPerCategory;
  updateAthleteFromTable: (new_athletes: AthleteInterface[]) => void;
  deleteAthleteFromTable: (athleteToDelete: AthleteInterface) => void;
  updateAgeClassFromTable: (newAgeClass: AgeClassInterface) => void;
}) {
  const {
    age_class,
    athletes,
    updateAthleteFromTable,
    deleteAthleteFromTable,
    updateAgeClassFromTable,
  } = props;
  const [opened, setOpened] = useState(!age_class?.closed);

  /** get each Category of an AgeClass with its Athletes */
  function getTableCategories() {
    if (!age_class?.categories) return;

    return age_class.categories.map((category) => {
      if (!category._id) throw new Error('How is it possible that a category id is null?');
      return (
        <CategorySubTable
          athletes={athletes[category._id]}
          updateAthleteFromTable={updateAthleteFromTable}
          deleteAthleteFromTable={deleteAthleteFromTable}
          key={category._id}
          modifications_disabled={age_class.closed}
          heavyweights_name={category.name}
        />
      );
    });
  }

  return (
    <>
      <AgeClassRow
        age_class={age_class}
        updateAgeClassFromTable={updateAgeClassFromTable}
        openChevron={() => setOpened((prev) => !prev)}
        opened={opened}
      />
      {opened && getTableCategories()}
    </>
  );
}
