import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { NewUserInterface, UserEnhancedInterface } from '../../schemas';
import { apiGet, apiPost } from '../../Services/Api/api';
import './admin.css';

export default function Admin() {
  const [users, setUsers] = useState<UserEnhancedInterface[]>();
  useEffect(() => {
    apiGet('admin').then((users_data) => setUsers(users_data));
  }, []);
  const navigate = useNavigate();

  function getTableRow(u: UserEnhancedInterface) {
    return (
      <tr key={u._id}>
        <td className='table-column-50'>{u.username}</td>
        <td className='table-column-25'>{u.n_athletes}</td>
        <td className='table-column-25'>
          {(u.is_admin && 'admin') || 'not admin'}
        </td>
      </tr>
    );
  }

  function formSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const form_data = new FormData(e.currentTarget);
    const form_props = Object.fromEntries(form_data);

    const new_user: NewUserInterface = {
      username: form_props.username as string,
      password: form_props.password as string,
      age_classes_to_use: {
        Bambini: (form_props?.Bambini as string) === 'on',
        Fanciulli: (form_props?.Fanciulli as string) === 'on',
        Ragazzi: (form_props?.Ragazzi as string) === 'on',
        'Esordienti A': (form_props?.['Esordienti A'] as string) === 'on',
        'Esordienti B': (form_props?.['Esordienti B'] as string) === 'on',
        Cadetti: (form_props?.Cadetti as string) === 'on',
        Juniores: (form_props?.Juniores as string) === 'on',
        Seniores: (form_props?.Seniores as string) === 'on',
      },
    };

    apiPost('admin', new_user).then(() => navigate(0));
  }

  return (
    <>
      {/* table */}
      <div className='table-container'>
        <div className='table-text'>User List</div>
        <table className='table'>
          <thead>
            <tr>
              <td className='table-column-50'>username</td>
              <td className='table-column-25'>N Athletes</td>
              <td className='table-column-25'>Is Admin</td>
            </tr>
          </thead>
          <tbody style={{ height: '100%' }}>
            {users?.map((u) => getTableRow(u))}
          </tbody>
        </table>
      </div>
      {/* add user form */}
      <div className='form-container'>
        <div className='form-title'>Add new User</div>
        <form className='admin-form' onSubmit={formSubmit}>
          <label>
            username
            <input type='text' name='username' id='username' required />
          </label>
          <label>
            password
            <input type='text' name='password' id='password' required />
          </label>
          <label>
            <input type='checkbox' name='Bambini' id='Bambini' />
            Bambini
          </label>
          <label>
            <input type='checkbox' name='Fanciulli' id='Fanciulli' />
            Fanciulli
          </label>
          <label>
            <input type='checkbox' name='Ragazzi' id='Ragazzi' />
            Ragazzi
          </label>
          <label>
            <input type='checkbox' name='Esordienti A' id='Esordienti A' />
            Esordienti A
          </label>
          <label>
            <input type='checkbox' name='Esordienti B' id='Esordienti B' />
            Esordienti B
          </label>
          <label>
            <input type='checkbox' name='Cadetti' id='Cadetti' />
            Cadetti
          </label>
          <label>
            <input type='checkbox' name='Juniores' id='Juniores' />
            Juniores
          </label>
          <label>
            <input type='checkbox' name='Seniores' id='Seniores' />
            Seniores
          </label>

          <button type='submit'>Submit</button>
        </form>
      </div>
    </>
  );
}
