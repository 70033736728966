import React from 'react';
import OrangeButton from '../../../Components/Buttons/OrangeButton';
import { AthleteColor } from '../common/AthleteColor';

/** two buttons to end the osaekomi and change side if given incorrectly */
export default function OskOtherSide(props: {
  opponent: AthleteColor;
  setHasOsk: (osk_owner: AthleteColor) => void;
  end: () => void;
}) {
  const { opponent, setHasOsk, end } = props;

  return (
    <div className='osk-buttons'>
      {opponent === 'WHITE' && (
        <OrangeButton onClickFunction={() => setHasOsk(opponent)}>
          Cambia lato
        </OrangeButton>
      )}

      <OrangeButton onClickFunction={() => end()}>
        Termina Osaekomi
      </OrangeButton>

      {opponent === 'RED' && (
        <OrangeButton onClickFunction={() => setHasOsk(opponent)}>
          Cambia lato
        </OrangeButton>
      )}
    </div>
  );
}
