import {
  AgeClassParamsInterface,
  MatchScoresInterface,
} from '../../../schemas';

/** check if an athlete has a point advantage over the other */
export function checkAdvantage(scores: MatchScoresInterface) {
  let is_white_winning = false;
  let is_red_winning = false;

  if (scores.white_ippon > scores.red_ippon) is_white_winning = true;
  if (scores.red_ippon > scores.white_ippon) is_red_winning = true;

  if (!is_white_winning && !is_red_winning) {
    if (scores.white_wazaari > scores.red_wazaari) is_white_winning = true;
    if (scores.red_wazaari > scores.white_wazaari) is_red_winning = true;
  }

  return { is_white_winning, is_red_winning };
}

/** check if someone met victory conditions or is in advantage when golden score, when scores change */
export function checkWinner(
  scores: MatchScoresInterface,
  params: AgeClassParamsInterface,
  max_shido: number,
  is_gs: boolean
) {
  let is_white_winning = false;
  let is_red_winning = false;

  if (scores.white_ippon >= params.ippon_to_win) is_white_winning = true;
  if (scores.white_wazaari >= params.wazaari_to_win) is_white_winning = true;

  if (scores.red_ippon >= params.ippon_to_win) is_red_winning = true;
  if (scores.red_wazaari >= params.wazaari_to_win) is_red_winning = true;

  if (scores.red_penalties >= max_shido) is_white_winning = true;
  if (scores.white_penalties >= max_shido) is_red_winning = true;

  if (is_gs && !is_white_winning && !is_red_winning) {
    const adv = checkAdvantage(scores);
    is_white_winning = adv.is_white_winning;
    is_red_winning = adv.is_red_winning;
  }

  return { is_white_winning, is_red_winning };
}
