import React from 'react';

/**
 * It renders a label with a description and an input
 * @returns A function that returns a label element.
 */
export default function InputRow(props: {
  /** text to show next to the input */
  children: React.ReactNode;
  /** input field value */
  value?: string | number;
  /** function to call when the input field is changed. Should change props.value */
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  /** type of the input (number, string, ecc) */
  type: string;
  datalist?: string[];
  /** extra parameter to check if the input is over a minumum */
  min?: number;
  /** extra parameter to check if the input is under a maximum */
  max?: number;
}) {
  const { children, value, onChange, type, datalist, min, max } =
    props;

  /**
   * If there is no datalist, return null, otherwise return a datalist with a list of options
   * @returns The datalist is being returned.
   */
  function getDatalist() {
    if (!datalist) return null;
    return (
      <datalist id='suggestion-list'>
        {datalist.map((text) => (
          <option key={text}>{text}</option>
        ))}
      </datalist>
    );
  }

  return (
    <label className='timer-label'>
      <span className='input-description'>{children}</span>
      <div className='input-container'>
        <input
          type={type}
          className='athlete-input'
          value={value || ''}
          onChange={onChange}
          min={min}
          max={max}
          // if there is a datalist, add to input the field 'list'
          {...datalist && { list: 'suggestion-list' }}
        />
        {getDatalist()}
      </div>
    </label>
  );
}
