import React from 'react';
import { getMouseIncrease } from '../common/getMouseIncrease';

/**
 * shows the score and it's name below.
 * When clicked with right mouse, it calls setScore with an increase of one.
 * When clicked with left mouse, it calls setScore with a decrease of one.
*/
export default function ScoreSingle(props: {
  score: number;
  setScore: (increase: number) => void;
  score_name: React.ReactNode;
}) {
  const { score, setScore, score_name } = props;

  return (
    <div
      className='score-flexbox'
      onMouseDown={(e) => setScore(getMouseIncrease(e))}
    >
      <div className='score-point'>{score}</div>
      <div className='score-text'>{score_name}</div>
    </div>
  );
}
