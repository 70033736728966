import React from 'react';
import { MatchScoresInterface } from '../../../schemas';
import ScoreShido from '../../MatchTimer/components/ScoreShido';
import ScoreSingle from '../../MatchTimer/components/ScoreSingle';

export default function SecondMonitorScoreRow(props: {
  scores: MatchScoresInterface;
}) {
  const { scores } = props;
  return (
    <>
      <ScoreShido score={scores.white_penalties} setScore={() => {}} />
      <ScoreSingle
        score={scores.white_ippon}
        setScore={() => {}}
        score_name={'Ippon'}
      />
      <ScoreSingle
        score={scores.white_wazaari}
        setScore={() => {}}
        score_name={'Waza Ari'}
      />
      <ScoreSingle
        score={scores.red_ippon}
        setScore={() => {}}
        score_name={'Ippon'}
      />
      <ScoreSingle
        score={scores.red_wazaari}
        setScore={() => {}}
        score_name={'Waza Ari'}
      />
      <ScoreShido score={scores.red_penalties} setScore={() => {}} />
    </>
  );
}
