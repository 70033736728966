import React, { useEffect } from 'react';
import { AthleteColor } from '../common/AthleteColor';
import OskBar from './OskBar';
import OskDisplayedTimer from './OskDisplayedTimer';
import OskOtherSide from './OskOtherSide';
import OskStartButton from './OskStartButton';

/**
 * shows the row for osaekomi timer and functions.
 * When the osk is ended by user or by reaching the max time, calls oskTimeEnds
 * uses show_osk_timer and setShowOskTimer to decide when to show the osk timer.
 * It should be shown when on or once off until the main timer is paused and then started again.
 */
export default function OskRow(props: {
  oskTimeEnds: (owner: AthleteColor, time: number) => void;
  max_time: number;
  show_osk_timer: boolean;
  setShowOskTimer: (b: boolean) => void;
  has_osk: AthleteColor;
  setHasOsk: (a: AthleteColor) => void;
  seconds: number;
  is_running: boolean;
  pause: () => void;
  start: () => void;
  reset: (offsetTimestamp?: Date | undefined, autoStart?: boolean | undefined) => void;
}) {
  const {
    oskTimeEnds,
    max_time,
    show_osk_timer,
    setShowOskTimer,
    has_osk,
    setHasOsk,
    seconds,
    is_running,
    pause,
    start,
    reset,
  } = props;

  function resume() {
    if (seconds >= max_time) return;
    start();
  }
  // we do not have an expire function on the stopwatch
  useEffect(() => {
    if (seconds >= max_time) {
      end();
    }
  }, [seconds]);

  function end() {
    oskTimeEnds(has_osk, seconds);
    setHasOsk('');
    pause();
  }

  function playDisplayedTimer() {
    // pause and resume is possible only for sonomama, so only when an osk is on
    if (!has_osk) return;
    if (is_running) return pause();
    return resume();
  }

  function startOsk(osk_owner: AthleteColor) {
    setHasOsk(osk_owner);
    reset(new Date(), true);
    setShowOskTimer(true);
  }

  // if the osaekomi is on and the user stops and play again the main match timer, the osk timer would disappear
  useEffect(() => {
    if (show_osk_timer) return;
    if (has_osk === '') return;
    setShowOskTimer(true);
  });

  const no_osk = (
    <>
      <OskStartButton osk_owner={'WHITE'} startOsk={startOsk} />
      <OskDisplayedTimer
        show_osk_timer={show_osk_timer}
        seconds={seconds}
        is_running={is_running}
        playDisplayedTimer={playDisplayedTimer}
      />
      <OskStartButton osk_owner={'RED'} startOsk={startOsk} />
    </>
  );

  const red_osk = (
    <>
      <OskOtherSide opponent={'WHITE'} setHasOsk={setHasOsk} end={end} />
      <OskDisplayedTimer
        show_osk_timer={show_osk_timer}
        seconds={seconds}
        is_running={is_running}
        playDisplayedTimer={playDisplayedTimer}
      />
      <OskBar has_osk={has_osk} seconds={seconds} max_time={max_time} />
    </>
  );

  const white_osk = (
    <>
      <OskBar has_osk={has_osk} seconds={seconds} max_time={max_time} />
      <OskDisplayedTimer
        show_osk_timer={show_osk_timer}
        seconds={seconds}
        is_running={is_running}
        playDisplayedTimer={playDisplayedTimer}
      />
      <OskOtherSide opponent={'RED'} setHasOsk={setHasOsk} end={end} />
    </>
  );

  switch (has_osk) {
    case '':
      return no_osk;
    case 'WHITE':
      return white_osk;
    case 'RED':
      return red_osk;
    case 'ERROR':
    default:
      return <></>;
  }
}
