import React from 'react';
import { getMouseIncrease } from '../common/getMouseIncrease';
import ScoreSingle from './ScoreSingle';

/**
 * shows the shidos with a yellow/red card for each one of them.
 * If there are more than the maximum number of shidos displayable, it returns a ScoreSingle.
 * Current maximum number of shidos displayable: 3.
 * When clicked with right mouse, it calls setScore with an increase of one.
 * When clicked with left mouse, it calls setScore with a decrease of one.
 */
export default function ScoreShido(props: {
  score: number;
  setScore: (increase: number) => void;
}) {
  const { score, setScore } = props;
  const max_shido_displayable = 3;

  if (score > max_shido_displayable) {
    return (
      <ScoreSingle score={score} setScore={setScore} score_name={'Shido'} />
    );
  }

  return (
    <div
      className='score-flexbox'
      onMouseDown={(e) => setScore(getMouseIncrease(e))}
    >
      <div className='shido-flexbox'>
        {score > 0 && (
          <img className='shido-img' src='/shido-yellow.png' alt='1 shido' />
        )}
        {score > 1 && (
          <img className='shido-img' src='/shido-yellow.png' alt='2 shido' />
        )}
        {score > 2 && (
          <img className='shido-img' src='/shido-red.png' alt='3 shido' />
        )}
      </div>
      <div className='score-text'>{`${score} Shido`}</div>
    </div>
  );
}
