import React from 'react';

/**
 * shows a timer. This timer only shows a given time and does not implement the timer functions.
 * changes its color based on the is_running variable (yellow: on, red: off).
 * when clicked, calls the function playDisplayedTimer, that should turn on and off the timer.
 * if is_osk_timer is true, uses different css and shows only the seconds
 */
export default function DisplayedTimer(props: {
  seconds: number;
  minutes?: number;
  is_running: boolean;
  playDisplayedTimer: () => void;
  is_osk_timer?: boolean;
}) {
  const {
    seconds,
    minutes = 0,
    is_running,
    playDisplayedTimer,
    is_osk_timer = false,
  } = props;

  function getDisplayedTime() {
    const full_minutes = minutes > 9 ? `${minutes}` : `0${minutes}`;
    const full_seconds = seconds > 9 ? `${seconds}` : `0${seconds}`;

    if (is_osk_timer) return `${full_seconds}`;
    return `${full_minutes}:${full_seconds}`;
  }

  return (
    <div id='match-timer-container'>
      <button
        className={
          (is_running && 'timer-button black-active') || 'timer-button black'
        }
        id={(is_osk_timer && 'osk-timer-button') || 'match-timer-button'}
        onClick={() => playDisplayedTimer()}
      >
        {getDisplayedTime()}
      </button>
    </div>
  );
}
