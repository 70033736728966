import React, { ReactNode } from 'react';

export type TournamentTableData = {
  _id: string;
  name: string;
  finished: boolean;
  tatami_number: number[];
  n_athletes: number;
  tournament_type: string;
};

export default function TournamentTable(props: {
  tournament_table_data: TournamentTableData[];
  active_tournament: string;
  setActiveTournament: (tournamentId: string) => void;
  no_results_message: ReactNode;
}) {
  const {
    tournament_table_data,
    active_tournament,
    setActiveTournament,
    no_results_message,
  } = props;

  function getRowClass(tour: TournamentTableData) {
    if (active_tournament === tour._id) return 'active-row';
    if (tour.finished) return 'finished-row';
    return '';
  }

  function getTableElements() {
    if (tournament_table_data.length === 0) {
      return (
        <tr className='table-empty'>
          <td colSpan={3}>{no_results_message}</td>
        </tr>
      );
    }

    return tournament_table_data.map((tour) => (
      <tr
        key={tour._id}
        className={`${getRowClass(tour)} cursor-pointer`}
        onClick={() => setActiveTournament(tour._id)}
      >
        <td className='table-column-50'>{tour.name}</td>
        <td className='table-column-40'>{tour.tournament_type}</td>
        <td className='table-column-10'>{tour.n_athletes}</td>
      </tr>
    ));
  }

  return (
    <table className='table'>
      <thead>
        <tr>
          <td className='table-column-50'>Categoria</td>
          <td className='table-column-40'>Tipo</td>
          <td className='table-column-10'>#</td>
        </tr>
      </thead>
      <tbody>{getTableElements()}</tbody>
    </table>
  );
}
