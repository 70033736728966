import React from 'react';
import { TournamentTableData } from './TournamentTable';

/**
 * active_tournament should be nTatami
 * setActiveTournament should be the api to reserve a tournament
 * In the future, generic chooseComponent that has as props getRowClass and getTableElements
 * (or something similar)
 */
export default function TournamentReserveTable(props: {
  tournament_table_data: TournamentTableData[];
  active_tournament: string;
  setActiveTournament: (tournamentId: string) => void;
}) {
  const { tournament_table_data, active_tournament, setActiveTournament } =
    props;

  function getRowClass(tour: TournamentTableData) {
    if (tour.tatami_number.some((n) => n === Number(active_tournament))) {
      return 'active-row';
    }
    if (tour.tatami_number.some((n) => n > 0)) {
      return 'finished-row cursor-pointer';
    }
    return 'cursor-pointer';
  }

  function getTableElements() {
    if (tournament_table_data.length === 0) {
      return (
        <tr className='table-empty'>
          <td colSpan={3}>Nessun Torneo Disponibile</td>
        </tr>
      );
    }

    return tournament_table_data.map((tour) => (
      <tr
        key={tour._id}
        className={getRowClass(tour)}
        onClick={() => setActiveTournament(tour._id)}
      >
        <td className='table-column-50'>{tour.name}</td>
        <td className='table-column-40'>{tour.tournament_type}</td>
        <td className='table-column-10'>{tour.n_athletes}</td>
      </tr>
    ));
  }

  return (
    <table className='table'>
      <thead>
        <tr>
          <td className='table-column-50'>Categoria</td>
          <td className='table-column-40'>Tipo</td>
          <td className='table-column-10'>#</td>
        </tr>
      </thead>
      <tbody className='higher-table'>{getTableElements()}</tbody>
    </table>
  );
}
