import React from 'react';

/**
 * It's a dropdown menu that takes an array of objects with value and name, and a selected_option, and
 * when you choose a new option, it calls a function to change the selected_option to the new one
 * @param props
 * @returns A React component
 */
export default function DropDown(props: {
  /** thing to show above the dropdown menu */
  children: React.ReactNode;
  /** array of objects with value and name */
  options: { value: string; name: string }[];
  /** the option selected (one can be preselected) */
  selected_option: string;
  /** change selected_option to a new, chosen one */
  chooseOption: (optionValue: string) => void;
}) {
  const { children, options, selected_option, chooseOption } = props;

  /**
   * We're mapping over the options array and returning an option element for each item in the array
   * @returns An array of option elements.
   */
  function getOptions() {
    return options.map((option) => (
      <option value={option.value} key={option.value}>
        {option.name}
      </option>
    ));
  }

  return (
    <div className='select-wrapper'>
      <label className='select-label'>
        {children}
        <select
          className='select'
          value={selected_option}
          onChange={(e) => chooseOption(e.target.value)}
        >
          <option className='void-option'>{children}</option>
          {getOptions()}
        </select>
      </label>
    </div>
  );
}
