import React from 'react';
import { AgeClassInterface } from '../../../schemas';
import { Modal } from '../../../Components/Modal/Modal';
import AgeClassForm from './AgeClassForm';

export default function AgeClassFormModal(props: {
  handleClose: () => void;
  age_class: AgeClassInterface;
  updateAgeClassFromTable: (newAgeClass: AgeClassInterface) => void;
}) {
  const { handleClose, age_class, updateAgeClassFromTable } = props;

  return (
    <Modal handleClose={() => handleClose()}>
      <div className='form-container'>
        <div className='form-title'>{`Impostazioni ${age_class.name}`}</div>
        <AgeClassForm
          handleClose={() => handleClose()}
          age_class={age_class}
          updateAgeClassFromTable={updateAgeClassFromTable}
        />
      </div>
    </Modal>
  );
}
